import React from 'react';

import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import {animationVariantsHorizontal, animationVariantsVertical, person} from "../assets";
import Page from "../common/Page";

const Sections = [
	{
		name: process.env.REACT_APP_PAGE_SECOND_SECTION_1_TITLE,
		children: [
			{
				icon: faCheckCircle,
				segments: [
					{
						text: process.env.REACT_APP_PAGE_SECOND_SECTION_1_TEXT_1
					}
				]
			},
			{
				icon: faCheckCircle,
				segments: [
					{
						text: process.env.REACT_APP_PAGE_SECOND_SECTION_1_TEXT_2
					}
				]
			},
			{
				icon: faCheckCircle,
				segments: [
					{
						text: process.env.REACT_APP_PAGE_SECOND_SECTION_1_TEXT_3
					}
				]
			}
		]
	},

	{
		name: process.env.PAGE_SECOND_SECTION_2_TITLE,
		children: [
			{
				icon: faCheckCircle,
				segments: [
					{
						text: process.env.REACT_APP_PAGE_SECOND_SECTION_2_TEXT_1
					}
				]
			},
			{
				icon: faCheckCircle,
				segments: [
					{
						text: process.env.REACT_APP_PAGE_SECOND_SECTION_2_TEXT_2
					}
				]
			},
			{
				icon: faCheckCircle,
				segments: [
					{
						text: process.env.REACT_APP_PAGE_SECOND_SECTION_2_TEXT_3
					}
				]
			},
			{
				icon: faCheckCircle,
				segments: [
					{
						text: process.env.REACT_APP_PAGE_SECOND_SECTION_2_TEXT_4
					}
				]
			}
		]
	},

	{
		name: process.env.REACT_APP_PAGE_SECOND_SECTION_3_TITLE,
		children: [
			{
				icon: faCheckCircle,
				segments: [
					{
						text: process.env.REACT_APP_PAGE_SECOND_SECTION_3_TEXT_1
					}
				]
			}
		]
	}
];

const SectionItem = ({name, children}) => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1
	});

	return (
		<motion.section className="mb-8 bg-black bg-opacity-80 py-2 px-4 rounded-md"
		                ref={ref}
		                initial="hidden"
		                animate={inView ? "visible" : "hidden"}
		                variants={animationVariantsHorizontal}
		                transition={{duration: 1, ease: "easeOut"}}>
			<h2 className="block text-[16px] font-semibold text-yellow-500 text-shadow shadow-black md:text-[20px] md:font-bold">{name}</h2>
			<ul className="mt-2 list-disc list-inside">
				{children.map((item, key) => {
					return <ChildItem icon={item.icon} segments={item.segments} key={key} />
				})}
			</ul>
		</motion.section>
	);
}

const ChildItem = ({icon, segments}) => {
	return (
		<li className="mt-2 flex items-start">
			<FontAwesomeIcon icon={icon} className="mt-2 mr-2 text-yellow-500" />
			<p className="text-md md:text-[19px]">
				{segments.map((segment, index) =>
						segment.highlighted ? (
							<span key={index} className="font-bold text-yellow-500">
				              {segment.text}
				            </span>
						) : (
							<span key={index}>{segment.text}</span>
						)
				)}
			</p>
		</li>
	);
}

const PageSecond = () => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1
	});

	return (
		<Page>
			<motion.h1 className="text-xl font-semibold mb-4 text-yellow-500 text-center md:text-3xl md:font-bold"
			           ref={ref}
			           initial="hidden"
			           animate={inView ? "visible" : "hidden"}
			           variants={animationVariantsVertical}
			           transition={{duration: 1, ease: "easeOut"}}>
				{process.env.REACT_APP_PAGE_SECOND_TITLE}
			</motion.h1>

			<div className="w-full max-w-screen-lg flex flex-col items-center px-8 md:flex-row md:px-0">
				<div className="md:w-2/3 md:pr-12">
					{Sections.map((section, key) => {
						return <SectionItem name={section.name} children={section.children} key={key}/>
					})}
				</div>

				<div className="hidden justify-center ml-4 mt-8 flex-col md:w-1/3 md:mt-0 md:flex">
					<img src={person} alt={process.env.REACT_APP_PAGE_SECOND_TITLE} className="max-w-full h-full"/>

					<div className="text-center font-bold mt-4">
						<p className="text-4xl text-yellow-500">{process.env.REACT_APP_PAGE_SECOND_AUTHOR}</p>
						<p>{process.env.REACT_APP_PAGE_SECOND_AUTHOR_EXPLAIN}</p>
					</div>
				</div>
			</div>
		</Page>
	);
}

export default PageSecond;
