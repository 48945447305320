import React, {useState} from 'react';

import {
	animationVariantsHorizontal,
	animationVariantsVertical, handleClickFanpage,
	stats_image_1,
	stats_image_2,
	stats_image_3,
	stats_image_4,
} from "../assets";

import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import {Autoplay, Navigation} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css/navigation';

import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Page from "../common/Page";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {fab} from "@fortawesome/free-brands-svg-icons";
import CheckDate from "../common/CheckDate";

const stats = [
	{ src: stats_image_1 },
	{ src: stats_image_2 },
	{ src: stats_image_3 },
	{ src: stats_image_4 },
];

const PageSixth = () => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1
	});

	const [isOpen, setIsOpen] = useState(false);
	const [currentImage, setCurrentImage] = useState(0);

	const openLightbox = (index) => {
		setCurrentImage(index);
		setIsOpen(true);
	};

	const closeLightbox = () => {
		setIsOpen(false);
	};

	return (
		<Page>
			<motion.h1 className="text-2xl font-semibold mb-8 text-center text-yellow-500 md:text-4xl md:font-bold"
			           ref={ref}
			           initial="hidden"
			           animate={inView ? "visible" : "hidden"}
			           variants={animationVariantsVertical}
			           transition={{duration: 0.5, ease: "easeOut"}}>
				{process.env.REACT_APP_PAGE_SIXTH_TITLE}
			</motion.h1>

			<motion.div className="w-full mx-auto max-md:container md:px-12"
			            ref={ref}
			            initial="hidden"
			            animate={inView ? "visible" : "hidden"}
			            variants={animationVariantsVertical}
			            transition={{duration: 0.5, ease: "easeOut"}}>
				<Swiper
					className="feedback-stats md:!py-6"
					modules={[Navigation, Autoplay]}
					spaceBetween={10}
					slidesPerView={1}
					breakpoints={{
						768: {
							slidesPerView: 3,
							spaceBetween: 20,
						},
					}}
					navigation={true}
					loop={true}
					autoplay={{
						delay: 6500,
						disableOnInteraction: true
					}}
				>

					{stats.map((photo, index) => {
						return (
							<SwiperSlide key={index} className="cursor-pointer hover:z-10"
							             onClick={() => openLightbox(index)}>
								<div className="flex flex-row justify-center aspect-square md:aspect-[2/3] lg:aspect-square">
									<img src={photo.src} alt={`Thống kê ${index + 1}`}
									     className="w-full h-full rounded transform transition-transform duration-500 md:hover:scale-110"/>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>

				{isOpen && (
					<Lightbox
						slides={stats.map(photo => ({src: photo.src}))}
						open={isOpen}
						index={currentImage}
						close={closeLightbox}
					/>
				)}

				<div className="mt-4 text-center">
					<motion.a href="#"
					          onClick={handleClickFanpage}
					          rel="noopener noreferrer"
					          className="w-[280px] bg-[#1877F2] text-white text-md inline-flex pl-3.5 pr-4 py-4 font-semibold rounded-md items-center space-x-3 mb-4 drop-shadow-2xl shadow-black"
					          ref={ref}
					          initial="hidden"
					          animate={inView ? "visible" : "hidden"}
					          variants={animationVariantsHorizontal}
					          transition={{duration: 1, ease: "easeOut"}}>
						<FontAwesomeIcon icon={fab.faFacebook}/>
						<span>{process.env.REACT_APP_PAGE_SIXTH_BUTTON_CTA} <CheckDate/></span>
					</motion.a>
				</div>
			</motion.div>
		</Page>
	);
}

export default PageSixth;
