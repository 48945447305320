import React from 'react';

import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import {
	animationVariantsHorizontal,
	animationVariantsVertical, handleClickMessenger,
	logic
} from "../assets";
import Page from "../common/Page";

const PageFourth = () => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0
	});

	return (
		<Page>
			<h1 className="text-xl font-semibold text-center mb-8 md:text-3xl md:font-bold">
				<p className="mb-4">{process.env.REACT_APP_PAGE_FOURTH_TITLE_1}</p>
				<p>{process.env.REACT_APP_PAGE_FOURTH_TITLE_2}</p>
			</h1>

			<div className="rounded-lg flex flex-row justify-center mb-6">
				<motion.img src={logic}
				            alt={process.env.REACT_APP_PAGE_FOURTH_TITLE_1}
				            className="rounded-lg w-full md:max-h-[65vh] md:w-2/3"
				            ref={ref}
				            initial="hidden"
				            animate={inView ? "visible" : "hidden"}
				            variants={animationVariantsVertical}
				            transition={{duration: 0.5, ease: "easeOut"}}
				/>
			</div>

			<motion.div className="bg-black bg-opacity-35 p-4 rounded-lg mb-8 max-w-4xl text-center text-yellow-500 font-bold text-[16px] md:text-3xl"
			            ref={ref}
			            initial="hidden"
			            animate={inView ? "visible" : "hidden"}
			            variants={animationVariantsVertical}
			            transition={{duration: 0.5, ease: "easeOut"}}>
				<p className="mb-6">{process.env.REACT_APP_PAGE_FOURTH_DESC_1}</p>
				<p>{process.env.REACT_APP_PAGE_FOURTH_DESC_2}</p>
			</motion.div>

			<div className="flex flex-row justify-center gap-x-4 md:text-2xl">
				<motion.a href={process.env.REACT_APP_WEB_REGISTER_URL}
				          target="_blank"
				          rel="noreferrer noppener"
				          className="text-sm text-center bg-yellow-500 text-black font-semibold py-2 px-8 rounded-md hover:bg-yellow-600 transition duration-300 md:text-2xl md:rounded-full"
				          ref={ref}
				          initial="hidden"
				          animate={inView ? "visible" : "hidden"}
				          variants={animationVariantsHorizontal}
				          transition={{duration: 0.5, ease: "easeOut"}}>

					{process.env.REACT_APP_WEB_REGISTER_CTA}
				</motion.a>

				<motion.a href="#"
				          onClick={handleClickMessenger}
				          target="_blank"
				          rel="noreferrer noppener"
				          className="text-sm text-center bg-blue-700 text-white font-semibold py-2 px-8 rounded-md hover:bg-blue-900 transition duration-300 md:text-2xl md:rounded-full"
				          ref={ref}
				          initial="hidden"
				          animate={inView ? "visible" : "hidden"}
				          variants={animationVariantsHorizontal}
				          transition={{duration: 0.5, ease: "easeOut"}}>
					{process.env.REACT_APP_WEB_REGISTER_SUPPORT_CTA}
				</motion.a>
			</div>
		</Page>
	);
}

export default PageFourth;
