// Images
export const logo = `${process.env.REACT_APP_BOARD_URL}/assets/images/logo.png`;
export const background = `${process.env.REACT_APP_BOARD_URL}/assets/images/background.png`;
export const background_mobile = `${process.env.REACT_APP_BOARD_URL}/assets/images/background_mobile.jpg`;
export const person = `${process.env.REACT_APP_BOARD_URL}/assets/images/person.png`;
export const benefitBg = `${process.env.REACT_APP_BOARD_URL}/assets/images/benefitBg.png`;

export const feedback_messenger = `${process.env.REACT_APP_BOARD_URL}/assets/images/feedback_messenger.png`;
export const feedback_image_1 = `${process.env.REACT_APP_BOARD_URL}/assets/images/feedback_image_1.png`;
export const  feedback_image_2 = `${process.env.REACT_APP_BOARD_URL}/assets/images/feedback_image_2.png`;
export const  feedback_image_3 = `${process.env.REACT_APP_BOARD_URL}/assets/images/feedback_image_3.png`;
export const  feedback_image_4 = `${process.env.REACT_APP_BOARD_URL}/assets/images/feedback_image_4.png`;
export const  feedback_image_5 = `${process.env.REACT_APP_BOARD_URL}/assets/images/feedback_image_5.png`;
export const  feedback_image_6 = `${process.env.REACT_APP_BOARD_URL}/assets/images/feedback_image_6.png`;
export const  feedback_image_7 = `${process.env.REACT_APP_BOARD_URL}/assets/images/feedback_image_7.png`;
export const  feedback_image_8 = `${process.env.REACT_APP_BOARD_URL}/assets/images/feedback_image_8.png`;
export const  feedback_image_9 = `${process.env.REACT_APP_BOARD_URL}/assets/images/feedback_image_9.png`;
export const  feedback_image_10 = `${process.env.REACT_APP_BOARD_URL}/assets/images/feedback_image_10.png`;

export const  logic = `${process.env.REACT_APP_BOARD_URL}/assets/images/logic.png`;

export const  stats_image_1 = `${process.env.REACT_APP_BOARD_URL}/assets/images/stats_image_1.png`;
export const  stats_image_2 = `${process.env.REACT_APP_BOARD_URL}/assets/images/stats_image_2.jpg`;
export const  stats_image_3 = `${process.env.REACT_APP_BOARD_URL}/assets/images/stats_image_3.png`;
export const  stats_image_4 = `${process.env.REACT_APP_BOARD_URL}/assets/images/stats_image_4.png`;

// Videos
export const  page_first_video = `${process.env.REACT_APP_BOARD_URL}/assets/videos/page_first_video.mp4`;
export const  feedback_video_1 = `${process.env.REACT_APP_BOARD_URL}/assets/videos/feedback_video_1.mp4`;
export const  feedback_video_2 = `${process.env.REACT_APP_BOARD_URL}/assets/videos/feedback_video_2.mp4`;
export const  feedback_video_3 = `${process.env.REACT_APP_BOARD_URL}/assets/videos/feedback_video_3.mp4`;
export const  feedback_video_4 = `${process.env.REACT_APP_BOARD_URL}/assets/videos/feedback_video_4.mp4`;
export const  feedback_video_5 = `${process.env.REACT_APP_BOARD_URL}/assets/videos/feedback_video_5.mp4`;
export const  feedback_video_6 = `${process.env.REACT_APP_BOARD_URL}/assets/videos/feedback_video_6.mp4`;

export const handleClickFanpage = () => {
	if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
		window.location.href = `fb://page/?id=${process.env.REACT_APP_PAGE_USERNAME}`;
	} else if (window.navigator.userAgent.match(/Android/i)) {
		window.location.href = `fb://page/${process.env.REACT_APP_PAGE_USERNAME}`;
	} else {
		window.location.href = `https://www.facebook.com/${process.env.REACT_APP_PAGE_USERNAME}`;
	}
};

export const handleClickMessenger = () => {
	if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
		window.location.href = `fb-messenger-public://user-thread/${process.env.REACT_APP_PAGE_MESSENGER_ID}`;
	} else if (window.navigator.userAgent.match(/Android/i)) {
		window.location.href = `intent://user/${process.env.REACT_APP_PAGE_MESSENGER_ID}/#Intent;scheme=fb-messenger;package=com.facebook.orca;end`;
	} else {
		window.location.href = `https://www.messenger.com/t/${process.env.REACT_APP_PAGE_MESSENGER_ID}`;
	}
};

export const animationVariantsHorizontal = {
	hidden: { opacity: 0, x: -200 },
	visible: { opacity: 1, x: 0 }
};

export const animationVariantsVertical = {
	hidden: { opacity: 0, y: -200 },
	visible: { opacity: 1, y: 0 }
};