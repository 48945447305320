import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRight, faDotCircle} from "@fortawesome/free-solid-svg-icons";

import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { Swiper, SwiperSlide } from "swiper/react";
import {Pagination, Autoplay, Navigation} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {
	animationVariantsHorizontal,
	animationVariantsVertical, handleClickMessenger,
	logo,
} from "../assets";

import Page from "../common/Page";

const Benefits = [
	{
		title: process.env.REACT_APP_PAGE_THIRD_SECTION_1_TITLE,
		contents: [
			process.env.REACT_APP_PAGE_THIRD_SECTION_1_TEXT_1
		]
	},

	{
		title: process.env.REACT_APP_PAGE_THIRD_SECTION_2_TITLE,
		contents: [
			process.env.REACT_APP_PAGE_THIRD_SECTION_2_TEXT_1
		]
	},
	{
		title: process.env.REACT_APP_PAGE_THIRD_SECTION_3_TITLE,
		contents: [
			process.env.REACT_APP_PAGE_THIRD_SECTION_3_TEXT_1
		]
	},

	{
		title: process.env.REACT_APP_PAGE_THIRD_SECTION_4_TITLE,
		contents: [
			process.env.REACT_APP_PAGE_THIRD_SECTION_4_TEXT_1
		]
	},

	{
		title: process.env.REACT_APP_PAGE_THIRD_SECTION_5_TITLE,
		contents: [
			process.env.REACT_APP_PAGE_THIRD_SECTION_5_TEXT_1
		]
	},
];

const BenefitItem = ({title, contents, isInSlide}) => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1
	});

	return (
		<motion.div className={`bg-white benefit-item bg-no-repeat bg-[length:150px_200px] bg-right-bottom text-black rounded-2xl shadow-lg shadow-black flex flex-col items-center sm:h-full ${isInSlide ? 'w-full' : ''}`}
		            ref={ref}
		            initial="hidden"
		            animate={inView ? "visible" : "hidden"}
		            variants={animationVariantsHorizontal}
		            transition={{duration: .3, ease: "easeOut"}}>

			<div className="w-full h-auto flex flex-col items-center justify-center bg-gray-800 py-3 mb-4 rounded-t-2xl">
				<img src={logo} alt={title} className="w-auto h-32"/>
			</div>

			<div className="w-full px-4 pb-6 md:h-full md:flex md:flex-col">
				<h2 className="text-md font-bold mb-2 text-ellipsis overflow-x-hidden whitespace-nowrap">{title}</h2>

				<ul className="text-left mb-4">
					{contents.map((content, i) => (
						<li key={i} className="mt-2 line-clamp-3">
							<FontAwesomeIcon icon={faDotCircle} className="mr-2"/>
							<span>{content}</span>
						</li>
					))}
				</ul>

				<div className="flex w-full justify-center md:block md:w-auto md:mt-auto md:max-w-[180px]">
					<a href="#" onClick={handleClickMessenger} target="_blank" rel="noopener noreferrer" className="accept-offer-button inline-block bg-yellow-500 benefit-button text-gray-800 rounded-md px-6 py-2 font-bold border border-black">
						<span>{process.env.REACT_APP_PAGE_THIRD_BUTTON_TITLE}</span>
						<FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
					</a>
				</div>
			</div>
		</motion.div>
	);
}

const PageThird = () => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1
	});

	return (
		<Page>
			<motion.h1 className="text-xl font-semibold mb-8 text-center md:text-3xl md:font-bold"
			           ref={ref}
			           initial="hidden"
			           animate={inView ? "visible" : "hidden"}
			           variants={animationVariantsVertical}
			           transition={{duration: 1, ease: "easeOut"}}>
				{process.env.REACT_APP_PAGE_THIRD_TITLE}
			</motion.h1>

			<div className="w-full mx-auto container">
				<div className="block">
					<div className="flex flex-row flex-wrap justify-center max-md:gap-y-8 md:-mx-4 md:gap-4 lg:-mx-8 lg:gap-8">
						{Benefits.map((benefit, index) => {
							return <div key={index} className="w-full hover:z-10 md:w-1/2 lg:w-1/4 transform transition-transform duration-500 hover:scale-110">
								<BenefitItem title={benefit.title} contents={benefit.contents} isInSlide={true} />
							</div>
						})}
					</div>
				</div>

				<div className="hidden">
					<Swiper
						className="benefit-slider md:!py-6"
						modules={[Navigation, Pagination, Autoplay]}
						spaceBetween={10}
						slidesPerView={1}
						breakpoints={{
							768: {
								slidesPerView: 3,
								spaceBetween: 20,
							}
						}}
						pagination={{clickable: true}}
						navigation={true}
						loop={true}
						autoplay={{
							delay: 3500,
							disableOnInteraction: true
						}}
					>
						{Benefits.map((benefit, key) => {
							return <SwiperSlide key={key} className="hover:z-10">
								<div className="flex flex-row justify-center">
									<BenefitItem title={benefit.title} contents={benefit.contents} isInSlide={true}/>
								</div>
							</SwiperSlide>
						})}
					</Swiper>
				</div>
			</div>
		</Page>
	);
}

export default PageThird;
