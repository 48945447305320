import React from 'react';
import PageFirst from './pages/PageFirst';
import PageSecond from "./pages/PageSecond";
import PageThird from "./pages/PageThird";
import PageFourth from "./pages/PageFourth";
import PageFifth from "./pages/PageFifth";
import PageSixth from "./pages/PageSixth";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {isSafari} from "react-device-detect";

import FloatingChatButton from "./common/FloatingChatButton";


function App() {
    return (
      <div className={`md:min-h-screen md:w-full md:h-full relative ${isSafari ? "is-safari" : ""}`}>
          <FloatingChatButton />

          <Swiper
              modules={[Pagination, Mousewheel]}
              slidesPerView={1}
              centeredSlides={true}
              direction="vertical"
              pagination={{
                  clickable: true,
              }}
              mousewheel={true}
              className="app-container-desktop !hidden h-screen w-full md:!block"
              nested={true}
              allowTouchMove={false}
          >
              <SwiperSlide>
                  <PageFirst />
              </SwiperSlide>

              <SwiperSlide>
                  <PageSecond />
              </SwiperSlide>

              <SwiperSlide>
                  <PageThird />
              </SwiperSlide>

              <SwiperSlide>
                  <PageFourth />
              </SwiperSlide>

              <SwiperSlide>
                  <PageSixth />
              </SwiperSlide>

              <SwiperSlide>
                  <PageFifth />
              </SwiperSlide>
          </Swiper>

          <div className="app-container-mobile md:hidden">
              <PageFirst />
              <PageSecond />
              <PageThird />
              <PageFourth />
              <PageSixth />
              <PageFifth />
          </div>
      </div>
    );
}

export default App;
