import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {handleClickMessenger, pageMessageId, pageUsername} from "../assets";

const FloatingChatButton = () => {
	return (
		<div className="fixed bottom-4 right-4 z-50">
			<a href="#" onClick={handleClickMessenger} target="_blank" rel="noopener noreferrer"
			   className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 px-4 rounded-full shadow-lg floating-chat-button">
				<FontAwesomeIcon icon={fab.faFacebookMessenger} size="2x" className="floating-chat-button-icon" />
			</a>
		</div>
	)
}

export default FloatingChatButton;