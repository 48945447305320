import React, { useState, useEffect } from 'react';

const CheckDate = () => {
	const [dateText, setDateText] = useState('');

	useEffect(() => {
		const updateDateText = () => {
			const currentTime = new Date();
			// Convert current time to UTC+7
			const currentUTCTime = new Date(currentTime.toLocaleString('en-US', { timeZone: 'Asia/Bangkok' }));

			const hours = currentUTCTime.getHours();
			let dateToShow;

			if (hours < 17) {
				// If before 17:00, show today's date
				dateToShow = currentUTCTime;
			} else {
				// If after 17:00, show tomorrow's date
				dateToShow = new Date(currentUTCTime);
				dateToShow.setDate(dateToShow.getDate() + 1);
			}

			const day = String(dateToShow.getDate()).padStart(2, '0');
			const month = String(dateToShow.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
			const year = dateToShow.getFullYear();

			const formattedDate = `${day}/${month}/${year}`;
			setDateText(`${formattedDate}`);
		};

		updateDateText();
		// Optionally update the date every minute
		const intervalId = setInterval(updateDateText, 60000);

		return () => clearInterval(intervalId);
	}, []);

	return <>{dateText}</>;
};

export default CheckDate;
